<template>
    <div class='admin-manage-wrap'>
        <Header :type='type'/>
        <transition name="el-fade-in-linear">
        <div class="admin-manage-container">
            <div class="container-out">
                <div>
                    <el-radio-group v-model="filterType" @change='filterTypeChange' text-color="#fff" fill="#4454FF">
                    <el-radio-button class='all radio-btn' label="All"></el-radio-button>
                    <el-radio-button class='del radio-btn' label="Deleted"></el-radio-button>
                    </el-radio-group>
                </div>
                <div style="display:flex;">
                    <el-input icon="search" v-model="serachInput" placeholder="Enter customer name..." @change="searchSellerData"></el-input>
                    <el-button icon="el-icon-search" @click="getSellerDataAPI" :loading="isFetching" circle></el-button>
                </div>
                <div class="admin-action-container" v-show='filterType=="All"'>
                    <div class='admin-action' @click="$router.push({name:'AdminAdd'})" @mouseenter="hoverIcon=1" @mouseleave="hoverIcon=-1">
                        <div class="action-img add" :class="{active: hoverIcon==1}"></div>
                        <div :style="hoverIcon==1?{color:`#4454FF`}:{}" style="margin-right:20px">Add</div>
                    </div>
                    <div class='admin-action' @click="delSelectedItem" @mouseenter="hoverIcon=2" @mouseleave="hoverIcon=-1">
                        <div class="action-img del" :class="{active: hoverIcon==2}"></div>
                        <div :style="hoverIcon==2?{color:`#4454FF`}:{}">Delete</div>
                    </div>
                </div>
                <div class="admin-action-container" v-show='filterType=="Deleted"'>
                    <div class='admin-action' @click="restoreSelectedItem">
                        <div class="action-img restore"></div>
                        <div style="margin-right:20px">Restore</div>
                    </div>
                </div>
            </div>
            <transition name="el-fade-in-linear">
            <div class="container-in">
                <el-table ref="multipleTable" :data="sellerData" tooltip-effect="dark" style="width: 100%" @selection-change="selectSeller" :cell-style="setCellStyle" v-loading='isFetching'>
                    <el-table-column type="selection"></el-table-column>
                    <el-table-column label="Avatar" align="center" min-width="50"><template slot-scope="scope"><img class='avatar-img' :style="{backgroundImage: `url(${scope.row.avatarUrl})`}"></template></el-table-column>
                    <el-table-column label="Customer" align="left"><template slot-scope="scope">{{ scope.row.companyName }}</template></el-table-column>
                    <el-table-column label="Activation" align="center" v-if="filterType=='All'"><template slot-scope="scope"><el-switch v-model="scope.row.active" @change='switchAccountStatus(scope.row)' active-color="#4454FF" inactive-color="#757575"></el-switch></template></el-table-column>
                    <el-table-column label="Ranking" align="center"><template slot-scope="scope"><el-button type="number" @click="changeRankValue(scope.row)" id=''>{{scope.row.ranking}}</el-button></template></el-table-column>
                    <el-table-column label="VIP" align="center"><template slot-scope="scope">
                        <el-select v-model="scope.row.vip" @change="(e)=>{selectedVipValue(e,scope.row)}">
                            <el-option :label="1" :value="1"></el-option>
                            <el-option :label="2" :value="2"></el-option>
                            <el-option :label="3" :value="3"></el-option>
                        </el-select></template></el-table-column>
                    <el-table-column label="Date" align="center"><template slot-scope="scope">{{ scope.row.createdAt.split('T')[0] }}</template></el-table-column>
                    <el-table-column label="Edit" align="center"><template slot-scope="scope"><el-button type="primary" icon="el-icon-edit" @click="editSellerProfile(scope.row.owner)"></el-button></template></el-table-column>
                    <div slot="empty" class="empty">
                        <span>Sorry, there's no relatived data</span>
                    </div>
                </el-table>
            </div>
            </transition>
            <div class="pre-footer-container">
                <div class="">
                    <el-pagination @current-change="paginationChange" :page-size="sellerPaginationData.pageSize" layout="prev, pager, next" :total="sellerPaginationData.total" background></el-pagination>
                </div>
            </div>
        </div>
        </transition>
        <Footer/>
    </div>
</template>
<style lang="scss">
@import '@/assets/scss/admin.scss';
</style>
<script>
import Header from "@/components/Header.vue";
import Footer from '@/components/Footer.vue';

const DEAFULT_SELLER_DATA = [];
const DEAFULT_PAGINATION = {
    page: 1,
    pageSize: 20,
    total: 0,
};

import {getSellerData, updateSellerData} from "@/api";
export default {
    data(){
        return{
            hoverIcon: -1,
            isFetching: true,
            filterValue: "",
            filterUIValue: "",
            type: 'admin_manage',
            filterType: 'All',
            serachInput: '',
            checked: false,
            selectedSellers: [],
            sellerData: DEAFULT_SELLER_DATA,
            sellerPaginationData: DEAFULT_PAGINATION
        }
    },
    components:{
        Header, Footer,
    },
    methods:{
        async switchAccountStatus(item){
            await updateSellerData({data:[{
                ownerId: item.owner,
                accountStatusStr: item.accountStatusStr==='active'?'unactive':'active',
            }]});
            await this.getSellerDataAPI(this.sellerPaginationData.page);
        },
        async filterTypeChange(){
            if(this.filterType==='Deleted')
                await this.getSellerDataAPI(1, 'deleted');
            else
                await this.getSellerDataAPI(1);
        },
        selectSeller(item){
            this.selectedSellers = [];
            if(this.filterType=='All'){
                //selected items need to be deleted
                for(let i=0;i<item.length;i++){
                    this.selectedSellers.push({
                        ownerId: item[i].owner,
                        accountStatusStr: 'deleted'
                    })
                }
            }
            if(this.filterType=='Deleted'){
                //selected items need to be restored
                for(let i=0;i<item.length;i++){
                    this.selectedSellers.push({
                        ownerId: item[i].owner,
                        accountStatusStr: 'active'
                    })
                }
            }
        },
        enterSellerEditPage(){
        },
        setCellStyle({ row, column, rowIndex, columnIndex }){
        },
        changeRankValue(item){
            this.$prompt('Please enter a value you want to change.', 'Change ranking value', {
                confirmButtonText: 'OK',
                cancelButtonText: 'Cancel',
                inputErrorMessage: 'Wrong pattern!',
                inputType: 'number',
                center: true,
            }).then(async ({ value }) => {
                await updateSellerData({data:[{
                    ownerId: item.owner,
                    ranking: parseInt(value),
                }]});
                this.refreshPageData();
                this.$message({
                    type: 'success',
                    message: `${item.companyName}'s ranking value changed to : ` + value,
                    duration: 1500,
                });
            }).catch((err) => {
                this.$message({
                    type: 'error',
                    message: `Something went wrong! please make sure your input is correct`,
                    duration: 1500,
                });
            });
        },
        refreshPageData(){
            if(this.filterType==='All'){
                this.getSellerDataAPI(this.sellerPaginationData.page);
            }
            else{
                this.getSellerDataAPI(this.sellerPaginationData.page, 'deleted');
            }
        },
        async selectedVipValue(eve, item=''){
            await updateSellerData({data:[{
                ownerId: item.owner,
                vip: eve,
            }]});
            this.refreshPageData();
        },
        delSelectedItem(){
            this.$confirm('* You can restore the deleted account in "Deleted"', 'Are you sure you want to delete selected accounts?', {
                confirmButtonText: 'Delete',
                cancelButtonText: 'Cancel',
                center: true,
                inputErrorMessage: 'Wrong pattern!'
            }).then(async () => {
                await updateSellerData({data: this.selectedSellers})
                await this.getSellerDataAPI(1);
                this.selectedSellers = [];
                this.$message({
                    type: 'success',
                    message: `You've just deleted selected sellers`
                });
            }).catch(() => {
                // this.$message({
                //     type: 'info',
                //     message: `You've just canceled this action`
                // });
            });
        },
        async restoreSelectedItem(){
            await updateSellerData({data: this.selectedSellers})
            await this.getSellerDataAPI(1, 'deleted');
            this.selectedSellers = [];
            this.$message({
                type: 'success',
                message: `You've just restore selected sellers`
            });
        },
        editSellerProfile(uid){
            this.$router.push({name:'AdminEdit', query: {sid: uid}});
        },
        paginationChange(page){
            if(this.filterType==='All'){
                this.getSellerDataAPI(page);
            }
            else{
                this.getSellerDataAPI(page, 'deleted');
            }
        },
        async getSellerDataAPI(page=1, type=''){
            const self = this;
            this.isFetching = true;
            let params = { page , 'accountStatusStr': type};

            if (this.filterValue) {
                params = {
                    ...params,
                    'keyword': self.filterValue,
                };
            }
            let res = await getSellerData({params});
            this.sellerData = res?.data?.data?.list || DEAFULT_SELLER_DATA;
            this.sellerPaginationData = res?.data?.data?.pagination || DEAFULT_PAGINATION;
            for(let i=0;i<this.sellerData.length;i++){
                this.sellerData[i].active = this.sellerData[i].accountStatusStr==='active'?true:false; 
            }
            this.isFetching = false;
        },
        searchSellerData(el){
            this.filterValue = el;
            this.getSellerDataAPI();
        },
    },
    mounted(){
        this.getSellerDataAPI(1);
    }
}
</script>